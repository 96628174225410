.App {
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header-menu{
  padding-left: calc(6.96721vw - 7.29508px);
  padding-right: calc(6.96721vw - 7.29508px);
  color: white;
  font-size: calc(30px - 0.1vw);
  position: sticky;
  display: flex;
  top: 30px;
  z-index: 10;
}

.menu{
  margin-left: auto;
  margin-right: 0;
  display: flex;
  gap: 4vw;
}

.menu-element {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.menu-element::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 5px;
  height: 0.07em;
  bottom: 0;
  left: 0;
  background: currentcolor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.menu-element:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.App-header {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-left: calc(6.96721vw - 7.29508px);
  padding-right: calc(6.96721vw - 7.29508px);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}